/* SKYCODE CSS
-------------------------------------------------- */
.sc-navbar {
  background-color: #fff;
}

.sc-subtitle {
  margin-bottom: 4rem;
}
.sc-subtitle-caption {
  color: #fff;
  padding-top: 2rem;
  bottom: 3rem;
  z-index: 10;
}
.sc-subtitle-item {
  height: 12rem;
  background-color: #268AFF;
}

.sc-subtitle-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

ul.sc-none 
{
  list-style-type:none;
  padding-left:2rem;
}

.sc-indentation
{
  margin-left: 1rem;
}

.sc-nowrap
{
  white-space: nowrap;
}


.sc-card-header
{
  color:#fff;
  background-color: #268AFF;
}

/* 
-------------------------------------------------- */
.sc-card-lap
{
	margin-bottom: 1rem
}

.sc-color-red
{
	background-color:#F4375B
}

.sc-color-orange
{
	background-color:#F7931E
}

.sc-color-green
{
	background-color:#55A646
}

.sc-color-base
{
	background-color:#007ECC
}


/* HOME
-------------------------------------------------- */
.sc-catchphrase
{
	padding:2rem;font-size: 1.5rem;
}

.sc-main-image
{
	height: 730px;background-image: url('../../assets/images/main_background.svg');background-position: center;background-repeat:repeat-x;
}

.sc-main-description
{
	margin-bottom:3rem
}


/* CONTENT
-------------------------------------------------- */
.sc-content-background
{
	height: 424px;background-image: url('../../assets/images/content_background.svg');background-position: center;background-repeat:repeat-x;
}